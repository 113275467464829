<template>
	<v-sheet class="main-detail">
		<div class="">
			<v-layout class="section-main-content">
				<div class="listing-sidebar border-right">
					<div class="d-flex align-center">
						<div class="d-flex flex-wrap py-3">
							<v-chip
								class="ml-1 mb-1 px-2 text-capitalize"
								color="cyan white--text"
								small
								v-if="leadData && leadData.barcode"
								>{{ leadData.barcode }}
							</v-chip>
							<!-- <v-chip
								class="ml-1 mb-1 px-2 text-capitalize"
								color="orange white--text"
								x-small
								v-if="leadData && leadData.status"
								>{{ leadData.status }}
							</v-chip> -->

							<!-- <StatusInput
								v-if="leadData && leadData.status"
								v-model="leadData.status"
								:items="statusList"
								v-on:change-with-text="(value) => updateOpportunity(value, 'status')"
							/> -->
							<!-- kk -- {{ testOpportunity }} -->
							<v-menu offset-y min-width="100px" style="padding: 0px 4px" rounded bottom>
								<template #activator="{ on, attrs }">
									<div
										v-bind="attrs"
										v-on="on"
										class="d-flex justify-space-between align-center ml-2"
										:style="{
											border: `1px solid ${leadStatusColor(leadStatus(leadData.status)).border}`,
										}"
										style="
											padding: 0px 6px;
											background-color: #f7faff;
											width: 135px;
											height: 24px;
											border-radius: 9px;
										"
									>
										<span
											class="text-capitalize"
											style="font-size: 13px"
											:class="leadStatusColor(leadStatus(leadData.status)).text + '--text'"
										>
											{{ leadStatus(leadData.status) }}
										</span>
										<v-progress-circular
											v-if="pageLoading"
											size="17"
											indeterminate
											width="3"
											:color="leadStatusColor(leadStatus(leadData.status)).text"
										></v-progress-circular>
										<v-icon
											v-else
											style="font-size: 22px"
											:color="leadStatusColor(leadStatus(leadData.status)).text"
											small
										>
											{{ attrs["aria-expanded"] == "true" ? "mdi-menu-up" : "mdi-menu-down" }}
										</v-icon>
									</div>
								</template>

								<v-list elevation>
									<v-list-item-group v-model="testOpportunity">
										<v-list-item
											@change="updateOpportunity(item, 'status')"
											class="list-item cursor-pointer p-1"
											v-for="(item, index) in statusList"
											:key="index"
										>
											<v-list-item-title class="pl-4">
												<div class="d-flex">
													<div
														:style="{
															backgroundColor: leadStatusColor(item.text).border,
															height: '15px',
															width: '15px',
															borderRadius: '50%',
														}"
													></div>
													&nbsp;
													<div>
														{{ item.text }}
													</div>
												</div>
											</v-list-item-title>
										</v-list-item>
									</v-list-item-group>
								</v-list>
							</v-menu>

							<v-chip
								class="ml-1 mb-1 px-2 text-capitalize"
								color="red white--text"
								x-small
								label
								v-if="leadData.is_converted == 1"
								>{{ "Customer Created" }}</v-chip
							>
						</div>
						<v-spacer></v-spacer>
						<v-btn depressed tile class="my-auto" v-on:click="goBackData()">
							<v-icon class="mr-1">mdi-keyboard-backspace</v-icon>
							back
						</v-btn>
					</div>
					<div class="text-center mb-3">
						<v-avatar color="grey lighten-3" size="70">
							<v-img src="https://fsm-v3.businessthrust.com/media/default/no-profile-image.png"></v-img>
						</v-avatar>
					</div>
					<div class="text-center">
						<v-tooltip top content-class="custom-top-tooltip">
							<template v-slot:activator="{ on, attrs }">
								<span v-on="on" v-bind="attrs">
									<i class="fa-solid fa-clock-rotate-left"></i>
									<p
										class="my-auto font-level-3-bold text-capitalize custom-title"
										v-if="leadData && leadData.company_name"
									>
										{{ leadData.company_name }}
									</p>

									<em v-else class="text-muted"> no company name </em>
								</span>
							</template>
							<span>Company Name</span>
						</v-tooltip>
					</div>
					<div class="d-flex justify-center mt-2">
						<div class="detail-label">Product Type : &nbsp;</div>
						<div class="value">
							<span v-if="leadData && leadData.product_type">
								<v-chip
									class="text-capitalize"
									color="green white--text"
									x-small
									label
									v-if="leadData && leadData.product_type"
									>{{ leadData.product_type.replace("-", " ") }}
								</v-chip>
							</span>
							<em class="text-muted" v-else> no product type </em>
						</div>
					</div>
					<div class="d-flex justify-center">
						<div class="detail-label">Created By : &nbsp;</div>
						<div class="value" v-if="leadData && leadData.added_by && leadData.added_by.display_name">
							<b>{{ leadData.added_by.display_name }}</b>
						</div>
						<em class="text-muted" v-else>no created by</em>
					</div>
					<div class="d-flex justify-center mb-2">
						<div class="detail-label">Last contacted : &nbsp;</div>
						<div class="value">
							<v-tooltip top content-class="custom-top-tooltip">
								<template v-slot:activator="{ on, attrs }">
									<div v-on="on" v-bind="attrs">
										<template v-if="leadData && leadData.added_at && leadData.contacted_today == 1">
											<b>{{ leadData.last_contact_formated }}</b>
										</template>
										<em v-else class="text-muted"> no last contacted </em>
									</div>
								</template>
								<span v-if="leadData && leadData.added_at && leadData.contacted_today == 1">{{
									formatDateTime(leadData.added_at)
								}}</span>
							</v-tooltip>
						</div>
					</div>
					<!-- ********************* Previous Code *************************   -->
					<div class="d-flex justify-content-center py-3" v-if="false">
						<div class="icon-fab mr-2">
							<v-tooltip top content-class="custom-top-tooltip">
								<template v-slot:activator="{ on, attrs }">
									<v-icon @click="open_task_drawer('Create')" color="white" v-bind="attrs" v-on="on">
										mdi-notebook-plus-outline
									</v-icon>
								</template>
								<span>Create Task</span>
							</v-tooltip>
						</div>
						<div class="icon-fab mr-2">
							<v-tooltip top content-class="custom-top-tooltip">
								<template v-slot:activator="{ on, attrs }">
									<v-icon @click="open_meeting_drawer('Create')" color="white" v-bind="attrs" v-on="on">
										mdi-plus
									</v-icon>
								</template>
								<span>Create Meeting</span>
							</v-tooltip>
						</div>
						<div class="icon-fab mr-2">
							<v-tooltip top content-class="custom-top-tooltip">
								<template v-slot:activator="{ on, attrs }">
									<v-icon @click="open_reminder_drawer('Create')" color="white" v-bind="attrs" v-on="on">
										mdi-clock-outline
									</v-icon>
								</template>
								<span>Create Reminder</span>
							</v-tooltip>
						</div>
					</div>
					<!--  ******************** Previous Code END *********************   -->
					<div class="mr-2 d-flex justify-center">
						<template v-if="leadData.is_converted != 1">
							<v-tooltip top content-class="custom-top-tooltip">
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										color="blue darken-4 text-white"
										v-bind="attrs"
										v-on="on"
										rounded
										class="mr-2 rounded-sm"
										depressed
										tile
										v-on:click="covertCustomer"
										><v-icon small left>mdi-account</v-icon>Convert</v-btn
									>
								</template>
								<span>Convert To Customer</span>
							</v-tooltip>
						</template>
						<v-btn
							color="blue darken-4 text-white"
							class="mr-2 rounded-circle"
							depressed
							x-small
							outlined
							rounded
							fab
							tile
							v-if="getPermission('lead:update')"
							v-on:click="routeToUpdate(lead_id)"
							><v-icon small>mdi-pencil</v-icon>
						</v-btn>
						<v-menu offset-y left bottom content-class="elevation-3 border" rounded="0" max-width="180px">
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									color="blue darken-4 text-white"
									v-on="on"
									v-bind="attrs"
									class="mr-2 rounded-circle"
									depressed
									x-small
									rounded
									outlined
									fab
									tile
									><v-icon>mdi-dots-vertical</v-icon>
								</v-btn>
							</template>
							<v-list class="pa-2">
								<v-list-item
									class="px-2"
									v-on:click="open_meeting_drawer('Create')"
									v-if="getPermission('meeting:create')"
								>
									<v-list-item-title> Create Meeting</v-list-item-title>
								</v-list-item>
								<v-list-item
									class="px-2"
									v-on:click="open_task_drawer('Create')"
									v-if="getPermission('task:create')"
								>
									<v-list-item-title> Create Task</v-list-item-title>
								</v-list-item>
								<v-list-item
									class="px-2"
									v-on:click="open_reminder_drawer('Create')"
									v-if="getPermission('reminder:create')"
								>
									<v-list-item-title> Create Reminder</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
					</div>
					<v-divider></v-divider>
					<div class="detals-summary">
						<div class="details-grp d-flex">
							<div class="value">
								<template v-if="leadData.is_public == 1">
									<Chip text="Public" color="pink" outlined text-color=""></Chip>
								</template>
							</div>
						</div>
					</div>
					<table class="w-100 side-table">
						<tr>
							<td style="width: 95px">Reminder Date</td>
							<td align="center" style="width: 10px">:</td>
							<td>
								<span class="fw-500" v-if="leadData && leadData.reminder && leadData.reminder.start_date"
									>{{ formatDateTime(leadData.reminder.start_date) }}
								</span>
								<em class="text-muted" v-else>No Reminder Date</em>
							</td>
						</tr>
					</table>
					<table class="w-100 side-table mt-5">
						<tr>
							<td style="width: 90px">Opportunity</td>
							<td align="center" style="width: 10px">:</td>
							<td>
								<div class="value">
									<v-chip
										class="mr-1 my-1 px-2 text-capitalize"
										color="orange white--text"
										x-small
										label
										v-if="leadData && leadData.opportunity == 'high-chance'"
										>{{ leadData.opportunity }}
									</v-chip>
									<v-chip
										class="mr-1 my-1 px-2 text-capitalize"
										color="cyan white--text"
										x-small
										label
										v-if="leadData && leadData.opportunity == 'low-chance'"
										>{{ leadData.opportunity }}
									</v-chip>
									<v-chip
										class="mr-1 my-1 px-2 text-capitalize"
										color="green white--text"
										x-small
										label
										v-if="leadData && leadData.opportunity == '50-50'"
										>{{ leadData.opportunity }}
									</v-chip>
									<v-chip
										class="mr-1 my-1 px-2 text-capitalize"
										color="blue white--text"
										x-small
										label
										v-if="leadData && leadData.opportunity == 'none'"
										>{{ leadData.opportunity }}
									</v-chip>
								</div>
							</td>
						</tr>
						<!-- <tr>
							<td>Contact</td>
							<td align="center" style="width: 10px">:</td>
							<td>
								<div class="value" v-if="true">
									{{ "John" }}
								</div>
								<em class="text-muted" v-else>no contact</em>
							</td>
						</tr> -->
						<tr>
							<td>Position</td>
							<td align="center" style="width: 10px">:</td>
							<td>
								<span class="fw-500" v-if="leadData && leadData.position">{{ leadData.position }}</span>
								<em class="text-muted" v-else> no position </em>
							</td>
						</tr>
						<tr>
							<td>Email(main)</td>
							<td align="center">:</td>
							<td>
								<div class="value" v-if="leadData && leadData.email">
									<div
										class="blue--text text--darken-4 cursor-pointer"
										v-on:click="copyNumber(leadData.email)"
									>
										<b>{{ leadData.email }}</b>
									</div>
								</div>
								<em class="text-muted" v-else>no email</em>
							</td>
						</tr>
						<tr>
							<td>Website</td>
							<td align="center">:</td>
							<td>
								<span
									class="cursor-pointer text-lowercase blue--text text--darken-4"
									v-if="leadData && leadData.website"
									v-on:click.prevent.stop="copyNumber(leadData.website)"
								>
									{{ leadData.website }}
								</span>
								<em class="text-muted" v-else> no website </em>
							</td>
						</tr>
						<tr>
							<td>Company No.</td>
							<td align="center">:</td>
							<td>
								<div class="value" v-if="leadData && leadData.phone">
									<span
										class="blue--text text--darken-4 cursor-pointer"
										v-on:click="copyNumber(leadData.phone, 'phone')"
									>
										<b> {{ leadData.phone }}</b>
									</span>
								</div>
								<em class="text-muted" v-else>no company number</em>
							</td>
						</tr>
					</table>
					<table class="w-100 side-table mt-5">
						<tr>
							<td>Source</td>
							<td align="center">:</td>
							<td>
								<span class="fw-500" v-if="leadData && leadData.source"
									><v-chip
										class="text-capitalize px-2"
										color="blue white--text"
										x-small
										label
										v-if="leadData && leadData.source"
										>{{ leadData.source.replace("-", " ") }}</v-chip
									>
								</span>
								<em class="text-muted" v-else> no source </em>
							</td>
						</tr>
						<tr>
							<td style="width: 90px">Amount</td>
							<td align="center" style="width: 10px">:</td>
							<td>
								<span class="fw-500" v-if="leadData && leadData.amount">
									<v-chip class="px-2" color="green white--text" label v-if="leadData && leadData.amount">
										<span style="font-size: 15px">
											{{ formatMoney(leadData.amount) }}
										</span>
									</v-chip>
								</span>
								<em class="text-muted" v-else>no amount</em>
							</td>
						</tr>
						<tr>
							<td>Assign To</td>
							<td align="center">:</td>
							<td>
								<span
									class="fw-500"
									v-if="leadData && leadData.assign_to && leadData.assign_to.display_name"
								>
									<v-chip small>
										<v-avatar left small>
											<img v-if="leadData.assign_to.profile_img" :src="leadData.assign_to.profile_img" />
											<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
										</v-avatar>
										<span class="fw-500"> {{ leadData.assign_to.display_name }}</span>
									</v-chip>
								</span>
								<em class="text-muted" v-else>no assign</em>
							</td>
						</tr>
						<tr>
							<td>Lead Date</td>
							<td align="center">:</td>
							<td>
								<div class="value" v-if="leadData && leadData.lead_date">
									<span
										><b>{{ formatDate(leadData.lead_date) }}</b></span
									>
								</div>
								<em class="text-muted" v-else>no lead date</em>
							</td>
						</tr>
						<tr>
							<td valign="top">Address</td>
							<td valign="top" align="center">:</td>
							<td valign="top">
								<span class="fw-500" v-if="leadData">
									<template v-if="leadData.address_line_1">
										{{ leadData.address_line_1 }},
										<br />
									</template>
									<template v-if="leadData.address_line_2"> {{ leadData.address_line_2 }}, </template>
									<template v-if="leadData.unit_number">
										{{ leadData.unit_number }},
										<br />
									</template>
									<template v-if="leadData.country"> {{ leadData.country }}, </template>
									<template v-if="leadData.postal_code">
										{{ leadData.postal_code }}
									</template>
								</span>
								<em
									class="text-muted"
									v-if="
										!leadData.address_line_1 &&
										!leadData.address_line_2 &&
										!leadData.unit_number &&
										!leadData.country &&
										!leadData.postal_code
									"
									>no address</em
								>
							</td>
						</tr>
					</table>
				</div>

				<div class="details-body">
					<div class="p-sticky bg-white">
						<v-layout class="px-3 py-2 flex-wrap">
							<div class="flex-grow-11">
								<v-tabs
									v-model="assetTab"
									background-color="transparent"
									color="blue"
									class="custom-tab-transparent w-100"
									active-class="blue darken-4 text-white"
									hide-slider
								>
									<v-tab href="#overview"><v-icon small left>mdi-note-plus-outline</v-icon>Overview</v-tab>
									<v-tab href="#notes" v-if="false"
										><v-icon small left>mdi-note-plus-outline</v-icon>Notes</v-tab
									>
									<!-- <v-tab href="#remark"><v-icon small left>mdi-note-plus-outline</v-icon>Remarks</v-tab> -->
									<v-tab href="#file"> <v-icon small left>mdi-file-document-multiple</v-icon>Files </v-tab>
									<v-tab href="#task" v-on:click="getTasks()">
										<v-icon small left>mdi-repeat</v-icon>Task</v-tab
									>
									<v-tab href="#meeting" v-on:click="getMeeting()">
										<v-icon small left>mdi-timeline-clock-outline</v-icon>Meeting</v-tab
									>
									<v-tab href="#reminder" v-on:click="getReminder()">
										<v-icon small left>mdi-alarm-check</v-icon>Reminder</v-tab
									>
									<v-tab href="#activity" v-on:click="getActivity()"
										><v-icon small left>mdi-wrench-outline</v-icon>Activity</v-tab
									>
								</v-tabs>
							</div>
							<v-spacer></v-spacer>
							<v-menu
								offset-y
								left
								bottom
								content-class="elevation-3 border"
								rounded="0"
								max-width="180px"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										color="blue darken-4 text-white"
										v-on="on"
										v-bind="attrs"
										class="ml-2"
										depressed
										tile
										><v-icon left small class="mdi-rotate-180">mdi-more</v-icon>More
									</v-btn>
								</template>
								<v-list class="pa-2">
									<v-list-item
										class="px-2"
										v-on:click="open_meeting_drawer('Create')"
										v-if="getPermission('meeting:create')"
									>
										<v-list-item-title> Create Meeting</v-list-item-title>
									</v-list-item>
									<v-list-item
										class="px-2"
										v-on:click="open_task_drawer('Create')"
										v-if="getPermission('task:create')"
									>
										<v-list-item-title> Create Task</v-list-item-title>
									</v-list-item>
									<v-list-item
										class="px-2"
										v-on:click="open_reminder_drawer('Create')"
										v-if="getPermission('reminder:create')"
									>
										<v-list-item-title> Create Reminder</v-list-item-title>
									</v-list-item>
								</v-list>
							</v-menu>
						</v-layout>
					</div>
					<div class="tab-layout-content px-4">
						<v-tabs-items v-model="assetTab">
							<v-tab-item value="overview">
								<div class="w-100">
									<ContactPerson
										:contact-detail="contactDetails"
										:related-id="lead_id"
										delete-url="lead-contact"
										primary-url="lead-primary"
										v-on:deleteSuccess="singleLead"
										v-on:open_contact_drawer="openContactDrawer($event)"
									></ContactPerson>
									<div class="border mt-3">
										<div class="card--header d-flex align-items-center border-bottom pa-2">
											<div class="font-level-3-bold">Internal Remark</div>
											<v-spacer></v-spacer>
										</div>
										<div class="card--body d-flex align-items-center pa-2">
											<template v-if="leadData.remark">
												{{ leadData.remark }}
											</template>
											<template v-else>
												<em class="text-muted">No Internal Remark</em>
											</template>
										</div>
									</div>
									<div class="border mt-3">
										<div class="card--header d-flex align-items-center border-bottom pa-2">
											<div class="font-level-3-bold">Email Reply</div>
											<v-spacer></v-spacer>
										</div>
										<div class="card--body d-flex align-items-center pa-2">
											<template v-if="leadData && leadData.email_reply">
												{{ leadData.email_reply }}
											</template>
											<template v-else>
												<em class="text-muted">No Email Reply</em>
											</template>
										</div>
									</div>
								</div>
							</v-tab-item>

							<v-tab-item value="notes" v-if="false">
								<AllNotesTab
									v-if="assetTab == 'notes'"
									:relatedId="lead_id"
									:relatedType="'lead-notes'"
									create-url="lead-note"
									get-url="lead-note"
								>
								</AllNotesTab>
							</v-tab-item>
							<!-- <v-tab-item value="remark">
								<AllNotesTab
								v-if="assetTab == 'remark'"
									:relatedId="lead_id"
									:relatedType="'lead-remark-notes'"
									create-url="lead-remark"
									get-url="lead-remark"
									:default-remakr="leadData.remark"
									:default-date="leadData.added_at"
									v-on:refress="singleLead"
					         	>
					     	</AllNotesTab>
							</v-tab-item> -->
							<v-tab-item value="file">
								<Files
									v-if="assetTab == 'file'"
									class="border"
									type="lead"
									:url="'lead'"
									:type-id="lead_id"
								>
								</Files>
							</v-tab-item>
							<v-tab-item value="task">
								<div v-if="taskData && taskData.length">
									<div v-for="(tasks, index) in taskData" :key="index">
										<TaskTab
											v-on:singleTaskEdit="taskEdit($event)"
											v-on:editTitle="open_task_drawer($event)"
											:open_details="!index ? true : false"
											:task="tasks"
											:index-task="index"
											v-on:taskSuccess="refreshTask"
											v-on:priorityChange="refreshTask"
										></TaskTab>
									</div>
								</div>
								<template v-else>
									<p class="m-0 row-not-found text-center">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There are no task at the moment.
									</p>
								</template>
							</v-tab-item>
							<v-tab-item value="meeting">
								<v-layout class="pa-2 border-light-grey min-height-40px">
									<v-flex class="font-level-3-bold my-auto">
										<v-icon color="blue darken-4" left>mdi-timeline-clock-outline</v-icon>
										Meeting
										<v-tooltip top content-class="custom-top-tooltip">
											<template v-slot:activator="{ on, attrs }">
												<v-btn
													v-if="getPermission('meeting:create')"
													v-on="on"
													v-bind="attrs"
													v-on:click="open_meeting_drawer('Create')"
													color="blue darken-4 text-white"
													class="ml-2 rounded-circle"
													depressed
													icon
													rounded
													outlined
													tile
													small
													min-width="24"
													width="24"
													height="24"
												>
													<v-icon small>mdi-plus</v-icon>
												</v-btn>
											</template>
											<span>Create Meeting</span>
										</v-tooltip>
									</v-flex>
									<v-flex class="text-right" v-if="false">
										<v-btn
											v-if="getPermission('meeting:create')"
											v-on:click="open_meeting_drawer('Create')"
											color="blue darken-4 text-white"
											class="ml-2"
											depressed
											tile
											><v-icon small left>mdi-plus</v-icon>Create Meeting</v-btn
										>
									</v-flex>
								</v-layout>
								<div v-if="meetingData && meetingData.length">
									<div v-for="(meetings, index) in meetingData" :key="index">
										<MeetingTab
											:open_details="false"
											v-on:editMeeting="editMeeting($event)"
											v-on:editTitle="open_meeting_drawer($event)"
											v-on:deleteMeeting="getMeeting"
											:index-meeting="index"
											:meeting="meetings"
											v-on:meetingSuccess="refreshMeeting"
										>
										</MeetingTab>
									</div>
								</div>
								<template v-else>
									<p class="m-0 row-not-found text-center">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There are no meeting at the moment.
									</p>
								</template>
							</v-tab-item>

							<v-tab-item value="reminder">
								<v-layout class="px-4 py-2">
									<v-tabs
										background-color="transparent"
										color="blue"
										class="custom-tab-transparent w-100"
										active-class="blue darken-4 text-white"
										hide-slider
										v-model="reminderTabs"
									>
										<v-tab href="#overdue" v-on:click="getReminder(1)">
											<v-icon small left>mdi-alarm-check</v-icon>Overdue ({{
												reminderCounting && reminderCounting.overdue_reminders_count
													? reminderCounting.overdue_reminders_count
													: 0
											}})</v-tab
										>
										<v-tab href="#upcoming" v-on:click="getReminder(2)">
											<v-icon small left>mdi-alarm-check</v-icon>upcoming ({{
												reminderCounting && reminderCounting.upcoming_reminders_count
													? reminderCounting.upcoming_reminders_count
													: 0
											}})</v-tab
										>
										<v-tab href="#complate" v-on:click="getReminder(3)">
											<v-icon small left>mdi-alarm-check</v-icon>Completed ({{
												reminderCounting && reminderCounting.completed_reminders_count
													? reminderCounting.completed_reminders_count
													: 0
											}})</v-tab
										>
									</v-tabs>
								</v-layout>
								<div class="tab-layout-content px-4">
									<v-tabs-items v-model="reminderTabs">
										<v-tab-item value="overdue">
											<div class="reminder-rows-container">
												<template v-if="reminderData && reminderData.length > 0">
													<div v-for="(reminder, index) in reminderData" :key="index">
														<ReminderTab
															v-on:editReminder="editReminderForm($event)"
															v-on:editTitle="open_reminder_drawer($event)"
															:open_details="!index ? true : false"
															:remind="reminder"
															:index-reminder="index"
															v-on:reminderSuccess="refreshReminder"
															v-on:reminderSuccessNew="refreshReminder($event)"
															v-on:deleteSuccess="deleteRefreshReminder($event)"
														></ReminderTab>
													</div>
												</template>
												<template v-else>
													<p class="m-0 row-not-found text-center">
														<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
														Uhh... There are no overdue reminders at the moment.
													</p>
												</template>
											</div>
										</v-tab-item>
										<v-tab-item value="upcoming">
											<div class="reminder-rows-container">
												<template v-if="reminderData && reminderData.length > 0">
													<div v-for="(reminder, index) in reminderData" :key="index">
														<ReminderTab
															v-on:editReminder="editReminderForm($event)"
															v-on:editTitle="open_reminder_drawer($event)"
															:open_details="!index ? true : false"
															:remind="reminder"
															:index-reminder="index"
															v-on:reminderSuccessNew="refreshReminder($event)"
															v-on:deleteSuccess="deleteRefreshReminder($event)"
														></ReminderTab>
													</div>
												</template>
												<template v-else>
													<p class="m-0 row-not-found text-center">
														<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
														Uhh... There are no upcoming reminders at the moment.
													</p>
												</template>
											</div>
										</v-tab-item>
										<v-tab-item value="complate">
											<div class="reminder-rows-container">
												<template v-if="reminderData && reminderData.length > 0">
													<div v-for="(reminder, index) in reminderData" :key="index">
														<ReminderTab
															v-on:editReminder="editReminderForm($event)"
															v-on:editTitle="open_reminder_drawer($event)"
															:open_details="!index ? true : false"
															:remind="reminder"
															:index-reminder="index"
															v-on:reminderSuccessNew="refreshReminder($event)"
															v-on:deleteSuccess="deleteRefreshReminder($event)"
														></ReminderTab>
													</div>
												</template>
												<template v-else>
													<p class="m-0 row-not-found text-center">
														<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
														Uhh... There are no complete reminders at the moment.
													</p>
												</template>
											</div>
										</v-tab-item>
									</v-tabs-items>
								</div>
							</v-tab-item>
							<v-tab-item value="activity">
								<div v-if="logData && logData.length">
									<v-timeline dense>
										<v-timeline-item
											color="blue darken-4"
											small
											v-for="(logs, index) in logData"
											:key="index"
										>
											<!-- <div > -->
											<ActivityTab :open_details="false" :log="logs" title="Lead"></ActivityTab>
											<!-- </div> -->
										</v-timeline-item>
									</v-timeline>
								</div>
								<template v-else>
									<p class="m-0 row-not-found text-center">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There are no activity at the moment.
									</p>
								</template>
							</v-tab-item>
						</v-tabs-items>
					</div>
				</div>
				<div class="listing-sidebar border-left" v-if="false">
					<v-expansion-panels v-model="panel" multiple>
						<v-expansion-panel depressed elevation-0>
							<v-expansion-panel-header hide-actions class="py-0 pl-3 pr-1 expansion-header">
								<div class="position-relative">
									<v-icon> mdi-menu-right </v-icon>
									Companies ({{ companyData ? companyData.length : 0 }})
								</div>
								<v-spacer></v-spacer>
								<div class="text-right">
									<v-btn
										depressed
										tile
										class="blue darken-4 my-auto"
										color="white--text"
										@click.stop="open_dialog.add_company = true"
									>
										<v-icon>mdi-plus</v-icon>
										Add
									</v-btn>
								</div>
							</v-expansion-panel-header>
							<v-expansion-panel-content class="elevation-0">
								<div v-if="showComapny">
									<template v-for="(companyInfo, index) in companyData">
										<div v-if="index <= 1" :key="index" class="expansion-content border-gray pa-0 pr-1 mb-2">
											<div class="pl-3 pr-0 py-1 position-relative">
												<v-menu offset-y left tile>
													<template v-if="companyInfo.primary < 1" v-slot:activator="{ on, attrs }">
														<v-icon v-bind="attrs" v-on="on" size="18" style="position: absolute; right: 0px"
															>mdi-dots-vertical</v-icon
														>
													</template>
													<v-list>
														<v-list-item-group>
															<v-list-item>
																<v-list-item-title
																	class="text-capitalize"
																	v-on:click="companyMarkPrimary(companyInfo.id)"
																	>Set Primary</v-list-item-title
																>
															</v-list-item>
														</v-list-item-group>
													</v-list>
												</v-menu>
												<div class="pb-5" v-if="companyInfo.primary > 0">
													<div class="lead-type-label border-bottom-gray border-right-gray">Primary</div>
												</div>
												<div class="primary--text" v-if="companyInfo">{{ companyInfo.company_name }}</div>
												<div class="primary--text" v-if="companyInfo">
													{{ companyInfo.company_domain_name }}
												</div>
												<div class="" v-if="companyInfo">{{ companyInfo.phone }}</div>
											</div>
										</div>
									</template>
								</div>
								<div v-if="hideCompany">
									<template v-for="(companyInfo, index) in companyData">
										<div :key="index" class="expansion-content border-gray pa-0 pr-1 mb-2">
											<div class="pl-3 pr-0 py-1 position-relative">
												<v-menu offset-y left tile>
													<template v-if="companyInfo.primary < 1" v-slot:activator="{ on, attrs }">
														<v-icon v-bind="attrs" v-on="on" size="18" style="position: absolute; right: 0px"
															>mdi-dots-vertical</v-icon
														>
													</template>
													<v-list>
														<v-list-item-group>
															<v-list-item>
																<v-list-item-title
																	class="text-capitalize"
																	v-on:click="companyMarkPrimary(companyInfo.id)"
																	>Set Primary</v-list-item-title
																>
															</v-list-item>
														</v-list-item-group>
													</v-list>
												</v-menu>
												<div class="pb-5" v-if="companyInfo.primary > 0">
													<div class="lead-type-label border-bottom-gray border-right-gray">Primary</div>
												</div>
												<div class="primary--text" v-if="companyInfo">{{ companyInfo.company_name }}</div>
												<div class="primary--text" v-if="companyInfo && companyInfo.company_domain_name">
													{{ companyInfo.company_domain_name }}
												</div>
												<div class="" v-if="companyInfo && companyInfo.phone">{{ companyInfo.phone }}</div>
											</div>
										</div>
									</template>
								</div>
								<!-- <div v-else>
									<div class="mt-1 pl-8 primary--text fw-600 cursor-pointer">No Company</div>
								</div> -->
								<!-- <div
									v-if="showComapny"
									class="mt-1 primary--text fw-600 cursor-pointer"
									v-on:click="showHideCompany()"
								>
									View All
								</div>
								<div
									v-if="hideCompany"
									class="mt-1 primary--text fw-600 cursor-pointer"
									v-on:click="showLessCompany()"
								>
									Show Less
								</div> -->
							</v-expansion-panel-content>
						</v-expansion-panel>
						<v-expansion-panel>
							<v-expansion-panel-header hide-actions class="py-0 pl-3 pr-1 expansion-header">
								<div class="position-relative">
									<v-icon> mdi-menu-right </v-icon>
									Deals ({{ dealData ? dealData.length : 0 }})
								</div>
								<v-spacer></v-spacer>
								<div class="text-right">
									<v-btn
										depressed
										tile
										class="blue darken-4 my-auto"
										color="white--text "
										@click.stop="open_dialog.add_deal = true"
									>
										<v-icon>mdi-plus</v-icon>
										Add
									</v-btn>
								</div>
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<div v-if="showDeal">
									<template v-for="(dealInfo, index) in dealData">
										<div v-if="index <= 1" :key="index" class="expansion-content border-gray pa-0 pr-1">
											<div class="pl-3 pr-0 py-1 position-relative">
												<div class="primary--text" v-if="dealInfo">{{ dealInfo.deal_name }}</div>
												<div class="">
													<span class="fw-500">Amounts :</span>
													<span v-if="dealInfo"> {{ dealInfo.amount }} </span>
												</div>
												<div class="">
													<span class="fw-500">Close date :</span
													><span v-if="dealInfo"> {{ dealInfo.close_date }}</span>
												</div>
												<div class="">
													<span class="fw-500">Stage :</span>
													<v-chip class="ma-2" color="green white--text" x-small v-if="dealInfo">
														{{ dealInfo.deal_stage }}
													</v-chip>
												</div>
											</div>
										</div>
									</template>
								</div>
								<div v-if="hideDeal">
									<template v-for="(dealInfo, index) in dealData">
										<div :key="index" class="expansion-content border-gray pa-0 pr-1">
											<div class="pl-3 pr-0 py-1 position-relative">
												<div class="primary--text" v-if="dealInfo">{{ dealInfo.deal_name }}</div>
												<div class="">
													<span class="fw-500">Amounts :</span>
													<span v-if="dealInfo"> {{ dealInfo.amount }} </span>
												</div>
												<div class="">
													<span class="fw-500">Close date :</span
													><span v-if="dealInfo"> {{ dealInfo.close_date }}</span>
												</div>
												<div class="">
													<span class="fw-500">Stage :</span>
													<v-chip class="ma-2" color="green white--text" x-small v-if="dealInfo">
														{{ dealInfo.deal_stage }}
													</v-chip>
												</div>
											</div>
										</div>
									</template>
								</div>

								<!-- <div
									v-if="showDeal"
									class="mt-1 primary--text fw-600 cursor-pointer"
									v-on:click="showHideDeal()"
								>
									View All
								</div>
								<div
									v-if="hideDeal"
									class="mt-1 primary--text fw-600 cursor-pointer"
									v-on:click="showLessDeal()"
								>
									Show Less
								</div> -->
							</v-expansion-panel-content>
						</v-expansion-panel>
						<!-- <v-expansion-panel>
							<v-expansion-panel-header hide-actions class="py-0 pl-3 pr-1 expansion-header">
								<div class="position-relative">
									<v-icon> mdi-menu-right </v-icon>
									Attachments ({{ attachmentData ? attachmentData.length : 0 }})
								</div>
								<v-spacer></v-spacer>
								<div class="text-right">
									<v-btn
										depressed
										tile
										class="blue darken-4 my-auto"
										color="white--text "
										@click.stop="uploadDialog()"
									>
										<v-icon>mdi-plus</v-icon>
										Add
									</v-btn>
								</div>
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<div v-if="attachmentData">
									<div v-if="showAttach">
										<div class="expansion-content pa-0">
											<template v-for="(attachmentInfo, index) in attachmentData">
												<div class="attachment-list border-gray mb-1" :key="index">
													<div class="d-flex py-1 px-2">
														<span class="icon">
															<v-icon
																class="mdi-16px mr-1 primary--text"
																v-on:click="downloadAttachment(attachmentInfo.url)"
																>mdi-download</v-icon
															>
														</span>
														<span
															v-if="attachmentInfo && attachmentInfo.name"
															class="ellipsis primary--text"
															style="max-width: 120px"
														>
															{{ attachmentInfo.name }}</span
														>&nbsp;
														<span class="size" v-if="attachmentInfo && attachmentInfo.size">
															({{ attachmentInfo.size }})</span
														>
														<v-spacer></v-spacer>
													</div>
												</div>
											</template>
										</div>
									</div>
									<div v-if="hideAttach">
										<div class="expansion-content pa-0">
											<template v-for="(attachmentInfo, index) in attachmentData">
												<div class="attachment-list border-gray mb-1" :key="index">
													<div class="d-flex py-1 px-2">
														<span class="icon">
															<v-icon
																class="mdi-16px mr-1 primary--text"
																v-on:click="downloadAttachment(attachmentInfo.url)"
																>mdi-download</v-icon
															>
														</span>
														<span
															v-if="attachmentInfo && attachmentInfo.name"
															class="ellipsis primary--text"
															style="max-width: 120px"
														>
															{{ attachmentInfo.name }}</span
														>&nbsp;
														<span v-if="attachmentInfo && attachmentInfo.size" class="size">
															({{ attachmentInfo.size }})</span
														>
														<v-spacer></v-spacer>
													</div>
												</div>
											</template>
										</div>
									</div>
									
								</div>
							</v-expansion-panel-content>
						</v-expansion-panel> -->
						<!-- <v-expansion-panel>
							<v-expansion-panel-header hide-actions class="py-0 px-3 expansion-header">
								<div class="position-relative">
									<v-icon> mdi-menu-right </v-icon>
									Quotations (4)
								</div>
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<div class="expansion-content border-gray pa-0">
									<div class="pl-3 pr-0 py-1 position-relative">
										<div class="primary--text">Bthrust - New quotions</div>
										<div class=""><span class="fw-500">Amounts :</span> - -</div>
										<div class=""><span class="fw-500">Close date :</span> April 30, 2022</div>
										<div class=""><span class="fw-500">Stage :</span> Appointment scheduled</div>
									</div>
								</div>
								<div class="mt-1 primary--text fw-600 cursor-pointer">View All</div>
							</v-expansion-panel-content>
						</v-expansion-panel>
						<v-expansion-panel>
							<v-expansion-panel-header hide-actions class="py-0 px-3 expansion-header">
								<div class="position-relative">
									<v-icon> mdi-menu-right </v-icon>
									Tickets (3)
								</div>
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<div class="expansion-content border-gray pa-0">
									<div class="pl-3 pr-0 py-1 position-relative">
										<div class="primary--text">Bthrust - New Tickets</div>
										<div class=""><span class="fw-500">Amounts :</span> - -</div>
										<div class=""><span class="fw-500">Close date :</span> April 30, 2022</div>
										<div class=""><span class="fw-500">Stage :</span> Appointment scheduled</div>
									</div>
								</div>
								<div class="mt-1 primary--text fw-600 cursor-pointer">View All</div>
							</v-expansion-panel-content>
						</v-expansion-panel> -->
					</v-expansion-panels>
				</div>
			</v-layout>
			<v-file-input
				multiple
				ref="uploadFile"
				class="d-none"
				v-on:change="uploadFile($event)"
			></v-file-input>
		</div>
		<!-- sidebar drawers -->
		<CreateNote
			:drawer="open_dialog.notes"
			:title-status="meeting_title"
			v-on:close="open_dialog['notes'] = false"
			:get-note-data="editNoteData"
			v-on:updateSuccess="updateSuccessNote"
			related="lead"
			:related-id="lead_id"
		>
		</CreateNote>
		<ContactUpdate
			:drawer="openContact"
			v-on:update_contact="updateContact"
			:contact-data="contactData"
			update-url="update-lead-contact"
			:related-id="lead_id"
			:type="'lead'"
			@close="openContact = false"
		>
		</ContactUpdate>
		<CreateTask
			v-if="open_dialog.task"
			:drawer="open_dialog.task"
			:title-status="meeting_title"
			:task-data="editTaskData"
			:attendies="attendieList"
			v-on:updateTask="refreshTask"
			v-on:close="open_dialog['task'] = false"
			related="lead"
			:related-id="lead_id"
		></CreateTask>
		<CreateMeeting
			v-if="open_dialog.meeting"
			:drawer="open_dialog.meeting"
			:title-status="meeting_title"
			:get-meeting-data="editMeetingData"
			:attendies="attendieList"
			v-on:updateMeetingSuccess="refreshMeeting"
			v-on:close="open_dialog['meeting'] = false"
			related="lead"
			:related-id="lead_id"
		></CreateMeeting>
		<CreateOrUpdateReminderV2
			v-if="open_dialog.reminder"
			:drawer="open_dialog.reminder"
			:reminderId="edit_reminder_id"
			related="lead"
			:relatedId="lead_id"
			v-on:success="refreshReminder"
			v-on:close="closeReminderDialog()"
		>
		</CreateOrUpdateReminderV2>
		<!-- <CreateReminder
			v-if="open_dialog.reminder"
			:drawer="open_dialog.reminder"
			:title-status="meeting_title"
			:reminder-single-data="editReminderData"
			:attendies="attendieList"
			v-on:updateRemindSuccess="refreshReminder"
			v-on:deleteSuccess="deleteRefreshReminder($event)"
			v-on:close="open_dialog.reminder = false"
			related="lead"
			:related-id="lead_id"
		></CreateReminder> -->
		<AddCompany
			v-on:updateCompanyList="getCompanyList()"
			:drawer="open_dialog.add_company"
			v-on:close="open_dialog['add_company'] = false"
		></AddCompany>
		<AddDeal
			v-on:updateDealList="getCompanyDealList()"
			:drawer="open_dialog.add_deal"
			v-on:close="open_dialog['add_deal'] = false"
		></AddDeal>

		<CreateLead :drawer="drawerCreate" v-on:close="drawerCreate = false"></CreateLead>

		<add-dialog
			:document-dialog="openUploadModel"
			v-on:close="openUploadModel = false"
			v-on:success="getLeadAttachment"
			:related-id="lead_id"
			:related-type="'lead'"
		></add-dialog>
		<FullDialog v-if="leadsDialog" dense :dialog="leadsDialog" content-class="testdata">
			<template #title
				>Leads {{ leadData.barcode }} Notes
				<v-spacer></v-spacer>
				<v-btn icon class="text-white" color="red darken-4" depressed tile @click="closeDialog">
					<v-icon dark left>mdi-close</v-icon>
					Close
				</v-btn>
			</template>
			<template #body>
				<AllNotesTab
					v-if="leadsDialog"
					:relatedId="lead_id"
					:relatedType="'lead-notes'"
					:siggest-list="notesUserArr"
					create-url="lead-note"
					get-url="lead-note"
				>
				</AllNotesTab>
			</template>
			<!-- <template v-slot:action>
				<v-btn class="text-white" color="red darken-4" depressed tile v-on:click="closeDialog">
					Close
				</v-btn>
			</template> -->
		</FullDialog>
		<div class="floating-btn-wrapper">
			<div class="position-relative">
				<div class="call-btn-underlay" style="background-color: rgb(243, 165, 165)"></div>
				<v-icon
					@click="open_reminder_drawer('Create')"
					style="font-size: 50px !important"
					color="red"
					small
					>mdi-alarm-check</v-icon
				>
			</div>
			<div class="position-relative">
				<div class="call-btn-underlay" style="background-color: rgb(43, 149, 105)"></div>
				<v-icon style="font-size: 50px !important" color="green" small v-on:click="leadsDialog = true"
					>mdi-chat
				</v-icon>
			</div>
		</div>
	</v-sheet>
</template>
<style lang="scss" scoped>
.theme--dark.v-overlay.v-overlay--active {
	z-index: 99 !important;
}

.side-table {
	td {
		padding: 2px 0;
	}
}
</style>
<script>
// import CreateReminder from "@/view/pages/leads/create/CreateReminder";
/* import CompanyDetail from "@/view/module/contacts/customer/components/CompanyDetail"; */
//import NotesTab from "@/view/pages/leads/components/NotesTab";
import { GET, QUERY, PATCH } from "@/core/services/store/request.module";
import { SET_MESSAGE } from "@/core/services/store/common.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
// import StatusInput from "@/view/components/StatusInput.vue";
/* import ImageTemplate from "@/view/components/Image"; */

import { toSafeInteger } from "lodash";

export default {
	name: "Leads-details",
	data() {
		return {
			//pageLoading:false,
			meeting_title: null,
			reminderTabs: "overdue",
			edit_reminder_id: 0,
			hideCompany: false,
			showComapny: true,
			hideDeal: false,
			showDeal: true,
			hideAttach: false,
			showAttach: true,
			openUploadModel: false,
			search_open: false,
			lead_id: 0,
			leadData: {},
			contactDetails: [],
			leadsDialog: false,
			viewType: false,
			noteData: null,
			taskData: [],
			notesUserArr: [],
			meetingData: [],
			reminderData: null,
			reminderCounting: null,
			logData: null,
			companyData: null,
			dealData: null,
			attachmentData: [],
			editNoteData: {},
			editMeetingData: null,
			editReminderData: {},
			editTaskData: null,
			users: null,
			contactData: {},
			openContact: false,
			relatedType: "lead",
			attendieList: {},
			allCompanyLIst: false,
			open_dialog: {
				notes: false,
				task: false,
				meeting: false,
				reminder: false,
				add_company: false,
				add_deal: false,
				add_attachment: false,
			},
			testOpportunity: null,
			panel: [0],
			drawerCreate: false,
			/* assetTab: "notes", */
			task_action: false,
			open_details: false,
			assign_note: "Add Description",
			file_list: [],
			statusList: [
				{
					id: 2,
					text: "Not Contacted",
					value: "not-contacted",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "red",
					count: 0,
					status_count: 2,
				},
				{
					id: 3,
					text: "Follow UP",
					value: "follow-up",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "green",
					count: 0,
					status_count: 0,
				},
				{
					id: 4,
					text: "Meeting",
					value: "meeting",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "blue",
					count: 0,
					status_count: 0,
				},
				{
					id: 5,
					text: "Quotation",
					value: "quotation",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "red",
					count: 0,
					status_count: 0,
				},
				{
					id: 6,
					text: "Rejected",
					value: "reject",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "red",
					count: 0,
					status_count: 0,
				},
				{
					id: 7,
					text: "Accepted",
					value: "accept",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "green",
					count: 0,
					status_count: 0,
				},
			],
		};
	},
	components: {
		Files: () => import("@/view/components/Files"),
		CreateLead: () => import("@/view/pages/leads/create/CreateLead"),
		CreateNote: () => import("@/view/pages/leads/create/CreateNote"),
		CreateTask: () => import("@/view/pages/leads/create/CreateTask"),
		CreateMeeting: () => import("@/view/pages/leads/create/CreateMeeting"),
		CreateOrUpdateReminderV2: () => import("@/view/pages/leads/create/CreateOrUpdateReminderV2"),
		AddCompany: () => import("@/view/pages/leads/create/AddCompany"),
		FullDialog: () => import("@/view/components/FullDialog"),
		AddDeal: () => import("@/view/pages/leads/create/AddDeal"),
		ActivityTab: () => import("@/view/pages/leads/components/ActivityTab"),
		ContactPerson: () => import("@/view/module/contacts/customer/components/ContactPerson"),
		TaskTab: () => import("@/view/pages/leads/components/TaskTab"),
		MeetingTab: () => import("@/view/pages/leads/components/MeetingTab"),
		ReminderTab: () => import("@/view/pages/leads/components/ReminderTab"),
		AddDialog: () => import("@/view/pages/leads/create/AddDialog"),
		AllNotesTab: () => import("@/view/pages/leads/components/NotesTab3"),
		ContactUpdate: () => import("@/view/module/contacts/customer/components/ContactUpdate.vue"),
		Chip: () => import("@/view/components/Chip"),
	},
	methods: {
		closeReminderDialog() {
			this.open_dialog.reminder = false;
			this.edit_reminder_id = 0;
		},
		leadStatus(status) {
			let found = this.statusList.find((v) => v.value == status);
			if (found) {
				return found.text;
			} else {
				return null;
			}
		},
		taskStatusColor: function (row, type) {
			if (type == "source") {
				return "blue--text";
			}
			if (type == "product_type") {
				return "green--text";
			}
			if (type == "status") {
				return "orange--text";
			}
			if (type == "opportunity") {
				return row.opportunity == "high-chance"
					? "orange--text"
					: row.opportunity == "low-chance"
					? "cyan--text"
					: row.opportunity == "50-50"
					? "green--text"
					: "blue--text";
			}
		},
		leadStatusColor(status) {
			switch (status) {
				case "Not Contacted":
					return { border: "grey", text: "grey" };
				case "Follow UP":
					return { border: "orange", text: "orange" };
				case "Meeting":
					return { border: "#2196f3", text: "blue" };
				case "Quotation":
					return { border: "#e91e63", text: "pink" };
				case "Rejected":
					return { border: "red", text: "red" };
				case "Accepted":
					return { border: "green", text: "green" };
				case "50-50":
					return { border: "#2196f3", text: "blue" };
				case "high-chance":
					return { border: "green", text: "green" };
				case "low-chance":
					return { border: "grey", text: "grey" };
				case "none":
					return { border: "grey", text: "grey" };
				case null:
					return { border: "grey", text: "grey" };
			}
		},
		copyNumber(data, type) {
			if (type == "phone") {
				data = data.split("-").pop();
			}
			navigator.clipboard.writeText(data);
			this.$store.commit(SET_MESSAGE, [
				{ model: true, message: `<b>${data}</b> - successfully coppied.` },
			]);
		},
		closeDialog() {
			this.leadsDialog = false;
		},
		routeToUpdate(id) {
			let filterQuery = this.$route.query;

			this.$router.push({
				name: "update-lead",
				params: { id },
				query: {
					...filterQuery,
					t: new Date().getTime(),
				},
			});
		},

		openContactDrawer(id) {
			this.contactData = {};
			this.openContact = true;
			const _this = this;
			_this.$store
				.dispatch(GET, {
					url: `single-lead-contact/${this.lead_id}/${id}`,
				})
				.then((data) => {
					this.contactData = data;
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		updateOpportunity(data, type) {
			if (this.pageLoading) return false;
			var typeKey = type;
			this.pageLoading = true;
			this.$store
				.dispatch(PATCH, {
					url: `update-opportunity/${this.lead_id}`,
					data: {
						[typeKey]: data.value,
					},
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: `Success ! ${data.text} updated successfully.` },
					]);
					this.singleLead();
				})
				.catch((error) => {
					console.log(error);
					//this.$emit("reload:content", true), console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		manageCreateNotes() {
			this.editNoteData = {};
			this.open_dialog.notes = true;
		},
		goBackData() {
			const filterQuery = this.$route.query;
			console.log("filterQuery => ", filterQuery);
			this.$router.push({
				name: "leads",
				query: {
					...filterQuery,
					t: new Date().getTime(),
					viewType: this.viewType,
				},
			});
		},
		covertCustomer() {
			const _this = this;
			_this.$router.push({
				name: "customer-create",
				query: {
					lead_id: _this.lead_id,
					t: new Date().getTime(),
				},
			});
		},
		deleteRefreshReminder(row) {
			this.getReminder(row.status);
		},

		duplicateLead() {
			const _this = this;
			_this.$router.push({
				name: "create-lead",
				query: {
					duplicate: _this.lead_id,
					t: new Date().getTime(),
				},
			});
		},
		open_meeting_drawer(name) {
			this.editMeetingData = {};
			this.attendieList = {};
			this.open_dialog.meeting = true;
			this.meeting_title = name;
		},
		open_task_drawer(name) {
			this.editTaskData = {};
			this.attendieList = {};
			this.open_dialog.task = true;
			this.meeting_title = name;
		},
		updateContact() {
			this.openContact = false;
			this.singleLead();
		},
		open_note_drawer(name) {
			this.open_dialog.notes = true;
			this.meeting_title = name;
		},
		open_reminder_drawer(name) {
			this.editReminderData = {};
			this.attendieList = {};
			this.open_dialog.reminder = true;
			this.meeting_title = name;
		},
		showHideCompany() {
			this.hideCompany = true;
			this.showComapny = false;
		},
		showLessCompany() {
			this.hideCompany = false;
			this.showComapny = true;
		},
		showHideDeal() {
			this.hideDeal = true;
			this.showDeal = false;
		},
		showLessDeal() {
			this.hideDeal = false;
			this.showDeal = true;
		},
		showHideAttach() {
			this.hideAttach = true;
			this.showAttach = false;
		},
		showLessAttach() {
			this.hideAttach = false;
			this.showAttach = true;
		},
		updateSuccessNote() {
			this.open_dialog.notes = false;
			this.getNotes();
		},
		refreshMeeting() {
			this.getMeeting();
		},
		refreshReminder(row) {
			console.log({ update: row });
			this.getReminder(row.status);
			// if(row.status_marked && row.status_marked =="complete"){
			// 	this.reminderTabs = "complate";
			// }
		},
		refreshTask() {
			this.getTasks();
		},
		editNote(id) {
			this.editNoteData = {};
			if (id > 0) {
				this.open_dialog.notes = true;
				this.$store
					.dispatch(GET, { url: `single-note/${id}` })
					.then((data) => {
						this.editNoteData = data;
					})
					.catch((error) => {
						console.log({ error });
					});
			}
		},
		taskEdit(id) {
			this.open_dialog.task = true;
			this.$store
				.dispatch(GET, { url: `single-task/${id}` })
				.then((data) => {
					this.editTaskData = data.tasks;
					this.attendieList = data.attendie[0];
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		editReminderForm(id) {
			this.open_dialog.reminder = true;
			this.edit_reminder_id = id;
		},
		editMeeting(id) {
			this.open_dialog.meeting = true;
			this.$store
				.dispatch(GET, { url: `single-meeting/${id}` })
				.then((data) => {
					this.editMeetingData = data.meeting;

					this.attendieList = data.attendie[0];
					console.log(this.editMeetingData, "editMeetingData");
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		pageTitle() {
			return "Lead Details";
		},
		uploadFile(data) {
			const file_size = (data.size / 1024).toFixed(1) + "kb";
			const data_opj = { icon: "mdi-file-check-outline", name: data.name, size: file_size };
			this.file_list.push(data_opj);
		},
		uploadDialog() {
			this.openUploadModel = true;
		},
		remove_file(index) {
			this.file_list.splice(index, 1);
		},
		singleLead() {
			this.$store
				.dispatch(GET, { url: `lead/${this.lead_id}` })
				.then((data) => {
					this.leadData = data.lead;
					this.contactDetails = data.contact;
					if (
						this.leadData &&
						this.leadData.assign_to &&
						this.leadData.assign_to.id &&
						this.notesUserArr.length == 0
					) {
						this.notesUserArr.push(this.leadData.assign_to);
					}
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		getNotes() {
			this.editNoteData = {};
			this.$store
				.dispatch(QUERY, { url: `note`, data: { related_id: this.lead_id, related_to: "lead" } })
				.then((data) => {
					this.noteData = data.rows;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		getTasks() {
			this.$store
				.dispatch(QUERY, { url: `task-lead`, data: { related_to: "lead", leadId: this.lead_id } })
				.then((data) => {
					this.taskData = data;
					/* this.open_dialog.task = false; */
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		getMeeting() {
			this.$store
				.dispatch(QUERY, { url: `meeting-lead`, data: { related_to: "lead", leadId: this.lead_id } })
				.then((data) => {
					this.meetingData = data;

					this.open_dialog.reminder = false;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		getReminder(type) {
			//this.pageLoading = true;
			if (!type) {
				this.reminderTabs = "overdue";
				type = 1;
			}
			this.$store
				.dispatch(QUERY, {
					url: `reminder`,
					data: { related_id: this.lead_id, related_to: "lead", status: type ? type : 1 },
				})
				.then((data) => {
					if (type == 1) {
						this.reminderTabs = "overdue";
					} else if (type == 2) {
						this.reminderTabs = "upcoming";
					} else if (type == 3) {
						this.reminderTabs = "complate";
					}
					this.reminderData = data.rows;
					this.reminderCounting = data.countings ? data.countings : {};
					//this.singleLead();
					//console.log(this.reminderData );
					//this.pageLoading = false;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		getActivity() {
			this.$store
				.dispatch(GET, { url: `lead-log/${this.lead_id}` })
				.then((data) => {
					this.logData = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		getCompanyList() {
			this.$store
				.dispatch(GET, { url: `lead/${this.lead_id}/company` })
				.then((data) => {
					this.companyData = data.rows;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		getCompanyDealList() {
			this.$store
				.dispatch(GET, { url: `lead/${this.lead_id}/company-deal` })
				.then((data) => {
					this.dealData = data.rows;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		getLeadAttachment() {
			this.$store
				.dispatch(GET, { url: `lead/${this.lead_id}/${this.relatedType}/attachment` })
				.then((data) => {
					this.attachmentData = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		downloadAttachment(url) {
			window.open(url, "_blank");
		},
		companyMarkPrimary(id) {
			this.$store
				.dispatch(PATCH, { url: `lead/${this.lead_id}/mark-primary/${id}` })
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Company marked as primary." },
					]);
					this.getCompanyList();
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		routeToUser() {
			this.$router.push("/users");
		},
	},
	computed: {
		assetTab: {
			set(val) {
				let query = { ...this.$route.query };
				query.tab = val;
				if (val != this.assetTab) {
					this.$router.replace({ query });
				}
			},
			get() {
				return this.$route.query.tab || "leads";
			},
		},
	},
	mounted() {
		if (!this.getPermission("lead:view") && this.lead_id > 0) {
			this.$router.replace({
				name: "leads",
				query: { t: new Date().getTime() },
				date: [],
			});
		}

		this.singleLead();
		this.getActivity();
		this.getReminder();
		this.getMeeting();
		this.getTasks();
		this.getCompanyList();
		this.getCompanyDealList();
		this.getLeadAttachment();
		this.viewType = this.$route.query.viewType;
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Lead", disabled: false, href: "/contacts/leads" },
			{ text: "Detail", disabled: true },
		]);
	},
	beforeMount() {
		this.lead_id = toSafeInteger(this.$route.params.id);
	},
};
</script>
<style>
.flex-grow-11 {
	flex: 1 0 100%;
	max-width: 86%;
}
</style>
